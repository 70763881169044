import { Link } from "gatsby"
import React from "react"
import styles from "./hero.module.css"
import iphone from "../images/iPhone-X.png"


const Hero = ({ children }) => (
  <>
    <div className={styles.container}>
      { children }
    </div>
    <div className={styles.phone}>
      <img width="271.5" height="541.5" src={iphone}/>
    </div>
    <div className={styles.stripe}></div>
  </>
)
//181
//361
export default Hero
