import { Link } from "gatsby"
import React from "react"
import styles from "./why.module.css"

import time from "../images/time.svg"
import trend from "../images/trend.svg"
import device from "../images/device.svg"

const Why = () => (
    <div className={styles.container}>
      <h1>Pourquoi utiliser Syndic en un clic ?</h1>
      <div className={styles.items}>
      <div className={styles.item}>
        <img width="69" height="69" src={time}/>
        <h2>GAIN DE TEMPS</h2>
        <p>40% de temps en plus pour le gestionnaire pour se consacrer à des tâches à forte valeur ajoutée.</p>
      </div>
      <div className={styles.item}>
        <img width="69" height="69" src={trend}/>
        <h2>VOTRE IMAGE VALORISEE</h2>
        <p>80% des actions menées par le syndic ne sont pas portées à la connaissance des copropriétaires.</p>
      </div>
      <div className={styles.item}>
        <img width="69" height="69" src={device}/>
        <h2>SATISFACTION CLIENTS</h2>
        <p>61% des copropriétaires souhaitent utiliser davantage les nouvelles technologies pour la gestion de leur immeuble.</p>
      </div>
      </div>
    </div>
)
export default Why
