// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Content from "../components/content"
import Features from "../components/features"
import Why from "../components/why"
import RDV from "../components/rdv"

const Gestionnaires = (props: PageProps) => (
  <Layout>
    <SEO title="Digitaliser votre syndic" description="Solution digitale pour faciliter le quotidien des gestionnaires syndic"/>
    <Hero>
      <h1>Une application mobile qui permet aux <b>gestionnaires</b> syndic d’interagir avec les résidents de façon intuitive et transparente.</h1>
      <p>Avec cet outil digital, le syndic gagne en réactivité et en efficacité en rendant visibles les opérations du quotidien et en échangeant facilement avec l’ensemble des résidents. </p>
    </Hero>
    <Content>
    <p>L’application mobile Syndic + permet au gestionnaire syndic de se <b>concentrer</b> sur des tâches à forte valeur ajoutée en le déchargeant des tâches chronophages et répétitives.</p>
    <p>En réduisant le nombre de sollicitations entrantes pour un même incident, le gestionnaire pourra alors se <b>focaliser</b> sur son coeur de métier : être disponible et à l’écoute de ses clients.</p>
    <p>Cette application mobile vise à faire gagner du temps au gestionnaire syndic, en <b>facilitant</b> les échanges avec les résidents et les prestataires techniques.</p>
    </Content>
    <RDV/>
    <Why/>
    <Features/>
  </Layout>
)

export default Gestionnaires
